// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

export const environments = {
  URL_CLUUD: 'https://cloud.visitrack.com/',
  URL_API: 'https://api.visitrack.com/api/',
   URL_NODE: 'https://www.vtentrena.com',
  // URL_NODE: 'http://localhost:3000',
//   URL_RESORCES: 'http://localhost:3000/uploads/Entrenamientos/'
   URL_RESORCES: 'https://www.vtentrena.com/uploads/Entrenamientos/'
   //https://www.vtentrena.com/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
