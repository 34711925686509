export const environment = {
  production: true
};

export const environments = {
  URL_CLUUD: 'https://cloud.visitrack.com/',
  URL_API: 'https://api.visitrack.com/api/',
   URL_NODE: 'https://www.vtentrena.com',
  // URL_NODE: 'http://localhost:3000',
//   URL_RESORCES: 'http://localhost:3000/uploads/Entrenamientos/'
URL_RESORCES: 'https://www.vtentrena.com/uploads/Entrenamientos/'
//https://www.vtentrena.com/
};

