import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ApiEntrenaService } from 'src/app/Services/Api/ApiEntrena.service';
import { StorageWebService } from 'src/app/Services/Storage/StorageWeb.service';
import { ToastService } from 'src/app/Services/Utilities/Toast.service';

@Component({
  selector: 'app-modal-users',
  templateUrl: './modal-users.component.html',
  styleUrls: ['./modal-users.component.scss'],
})
export class ModalUsersComponent implements OnInit {

  listDesigns = [];
  text = '';

  @Input() user;

  constructor(
    private router: Router,
    private apiEntrena: ApiEntrenaService,
    private storage: StorageWebService,
    private toasCtrl: ToastService,
    private alertCtrl: AlertController,
    private modal: ModalController
  ) { }

  ngOnInit() {
  }

  ionViewWillEnter(){
   this.ListDesigners();
  }

  async ListDesigners() {
    const login = await this.storage.getLogin();

    if ( login ) {
        const id = login[0].data.role === 'USER_ENTRENA' ? login[0].data.parentAdmin : '';
        this.apiEntrena.readDesignersAll(login[0].token, id).subscribe((data: any) => {
          if (data.status === 'ok') {
            data.response.forEach((desing) => {
              desing.active = false;
              if (desing.keys) {
                const validate = desing.keys.filter((item) => {
                  return item === this.user;
                })
  
                if (validate.length > 0) {
                  desing.active = true;
                }
              }
            })
            this.listDesigns = data.response;
          } else {
            this.toasCtrl.newCreatedToast('Could not create design, please try again later', false)
          }
        }, (error) => {
          this.toasCtrl.newCreatedToast('Could not create design, please try again later', false)
        })
    }
  }

  async assign(id, status, idx) {
    const login = await this.storage.getLogin();

    if ( login ) {
        this.apiEntrena.addUsersDesing(login[0].token, {id, user: this.user}).subscribe((data: any) => {
          if (data.status === 'ok') {
            if (status) {
              this.toasCtrl.newCreatedToast('Usuario removido correctamente', true)
            } else {
              this.toasCtrl.newCreatedToast('Usuario asignado correctamente', true)
              
            }
            this.listDesigns[idx].active = !status;
          } else {
            this.toasCtrl.newCreatedToast('No se pudo asignar el usuario', false);
          }
        }, (error) => {
          this.toasCtrl.newCreatedToast('No se pudo asignar el usuario', false)
        });
    }
  }

  toggleUser() {

  }

  changeText( event ) {

  }

}
