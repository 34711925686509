import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
     name: 'filterUserRepository'
})

export class FilterUserRepositoryPipe implements PipeTransform {
     
     transform(arrayUsers: any[], textFilter: string, arrayPermiss: any[]) {
          console.log(arrayPermiss, arrayUsers, textFilter);
          textFilter = textFilter.toLocaleLowerCase();

          arrayUsers.forEach((item) => {
               if (arrayPermiss.find((id) => id === item.ID)) {
                    item.checked = true;
               }
          });

          if (textFilter === '') {
               return arrayUsers;
          } else {
               arrayUsers = arrayUsers.filter((item) => {
                    const text: string = item.name ? item.name.toLowerCase() : item.Name.toLowerCase();
                    if (text.includes(textFilter)) {
                         return item;
                    }
               });
          }

          

          return arrayUsers;
     }
}