import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrocdo'
})
export class FiltroCDOPipe implements PipeTransform {

  transform(value: any[], filtroby: string) {
    console.log(value, 'valor')

    

    return filtroby == 'Todos' ? value : value.filter((item) => item.CompanyStatusName == filtroby);
  }

}
