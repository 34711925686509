import { ModalAssentsComponent } from './modal-assents/modal-assents.component';
import { ModalUsersComponent } from './modal-users/modal-users.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { TitleComponent } from './FTYS/title/title.component';
import { TextLineComponent } from './FTYS/text-line/text-line.component';
import { DateComponent } from './FTYS/date/date.component';
import { TimeComponent } from './FTYS/time/time.component';
import { AddressComponent } from './FTYS/address/address.component';
import { CellPhoneComponent } from './FTYS/cell-phone/cell-phone.component';
import { EmailComponent } from './FTYS/email/email.component';
import { PhoneComponent } from './FTYS/phone/phone.component';
import { ParagrahpComponent } from './FTYS/paragrahp/paragrahp.component';
import { TextareaComponent } from './FTYS/textarea/textarea.component';
import { NumericValueComponent } from './FTYS/numeric-value/numeric-value.component';
import { RadioButtonsComponent } from './FTYS/radio-buttons/radio-buttons.component';
import { CheckboxComponent } from './FTYS/checkbox/checkbox.component';
import { DateTimeComponent } from './FTYS/date-time/date-time.component';
import { HyperlinkComponent } from './FTYS/hyperlink/hyperlink.component';
import { FormsComponent } from './FTYS/forms/forms.component';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../Pipes/pipes.module';
import { AsignacionnettsegurComponent } from './asignacionnettsegur/asignacionnettsegur.component';
import { OpcionesCDOComponent } from './opciones-cdo/opciones-cdo.component';
import { ControlcdoasistencialComponent } from './controlcdoasistencial/controlcdoasistencial.component';
import { CargaTaskComponent } from './carga-task/carga-task.component';
import { UsariosInverComponent } from './Inverpack/usarios-inver/usarios-inver.component';
import { ProximosComponent } from './oszford/proximos/proximos.component';
import { MenuOszfordComponent } from './menu-oszford/menu-oszford.component';
import { UsersDiamanteComponent } from './users-diamante/users-diamante.component';
import { CirculocerradoComponent } from './circulocerrado/circulocerrado.component';

@NgModule({
     declarations: [
          MenuComponent,
          TitleComponent,
          TextLineComponent,
          DateComponent,
          TimeComponent,
          AddressComponent,
          CellPhoneComponent,
          EmailComponent,
          PhoneComponent,
          ParagrahpComponent,
          TextareaComponent,
          NumericValueComponent,
          RadioButtonsComponent,
          CheckboxComponent,
          DateTimeComponent,
          HyperlinkComponent,
          FormsComponent,
          ModalUsersComponent,
          ModalAssentsComponent,
          AsignacionnettsegurComponent,
          OpcionesCDOComponent,
          ControlcdoasistencialComponent,
          CargaTaskComponent,
          UsariosInverComponent,
          ProximosComponent,
          MenuOszfordComponent,
          UsersDiamanteComponent,
          CirculocerradoComponent

     ],
     imports: [
          CommonModule,
          FormsModule,
          IonicModule,
          PipesModule
     ],
     exports: [
          MenuComponent,
          TitleComponent,
          TextLineComponent,
          DateComponent,
          TimeComponent,
          AddressComponent,
          CellPhoneComponent,
          EmailComponent,
          PhoneComponent,
          ParagrahpComponent,
          TextareaComponent,
          NumericValueComponent,
          RadioButtonsComponent,
          CheckboxComponent,
          DateTimeComponent,
          HyperlinkComponent,
          FormsComponent,
          ModalUsersComponent,
          ModalAssentsComponent,
          AsignacionnettsegurComponent,
          OpcionesCDOComponent,
          ControlcdoasistencialComponent,
          CargaTaskComponent,
          UsariosInverComponent,
          ProximosComponent,
          MenuOszfordComponent,
          UsersDiamanteComponent,
          CirculocerradoComponent
     ]
})

export class ComponentsModule {

}

