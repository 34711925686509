import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userss'
})
export class UsersPipe implements PipeTransform {

  transform(value: any[], type: string): unknown {
    return value.filter((item) => item.type === type);
  }

}
