import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroActivo'
})
export class FiltroActivooPipe implements PipeTransform {

  transform(value: any[], text: string) {
   
    text = text.toLowerCase()

    return text == '' ? value : value.filter((item) => {
        let name = item.Name.toLowerCase();
        let last = item.LocationName.toLowerCase();

   
        return name.includes(text) || last.includes(text);
    });
  }

}
