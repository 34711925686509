import { CanActivate, Router } from '@angular/router';
import { StorageWebService } from '../Services/Storage/StorageWeb.service';
import { Injectable } from '@angular/core';

@Injectable()
export class LoginGuard implements CanActivate {

     constructor(
          private storage: StorageWebService,
          private router: Router
     ) { }

     canActivate(): Promise<boolean> {
          return this.storage.getLogin().then((data) => {
             if (data.length > 0) {
                  if (data[0].typeApp === '2') {
                    this.router.navigate(['/list-my-designs']);
                  } else {
                    this.router.navigate(['/forms']);
                  }
                  return false;
             } else {
                  return true;
             }
          }).catch(() => {
               console.log('Error en auth/serve-store');
               return true;
          });
     }

}


