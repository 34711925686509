import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text-line',
  templateUrl: './text-line.component.html',
  styleUrls: ['./text-line.component.scss'],
})
export class TextLineComponent implements OnInit {
  @Input() lab;
  @Input() required;
  @Input() hel;
  @Input() pho;
  @Input() readonly;
  @Input() id;

  constructor() { }

  ngOnInit() {
    if (this.pho === '') {
      this.pho = 'Escriba aqui';
    }
  }


}
