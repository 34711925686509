import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ApiService } from 'src/app/Services/Api/Api.service';

@Component({
  selector: 'app-opciones-cdo',
  templateUrl: './opciones-cdo.component.html',
  styleUrls: ['./opciones-cdo.component.scss'],
})
export class OpcionesCDOComponent implements OnInit {

  @Input() opciones;

  color = '';

  constructor(
    private popover: PopoverController,
    private api: ApiService
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {

    if (this.color == 'CANCELADO POR DEMORA') {
      return 'gris'
    }
    if (this.color == 'Completado') {
      return  'verde'
    }

    if (this.color == 'EN PROCESO DE ASEO') {
      return  'amarillo'
    }

    if (this.color == 'PENDIENTE ASEO') {
      return   'rojo'
    }

    if (this.color == 'ELIMINADA POR LIMPIEZA PREVIA A SOLICITUD') {
      return   'azuñ'
    }

  }


  seleccion(item) {
    this.popover.dismiss({
      by: item
    })
  }

}
