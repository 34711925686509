import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { ApiService } from 'src/app/Services/Api/Api.service';
import { LoadingService } from 'src/app/Services/Utilities/Loading.service';
import { ToastService } from 'src/app/Services/Utilities/Toast.service';
import * as _ from 'underscore';


@Component({
  selector: 'app-proximos',
  templateUrl: './proximos.component.html',
  styleUrls: ['./proximos.component.scss'],
})
export class ProximosComponent implements OnInit {

  @Input() tkn;
  @Input() list: any;
  @Input() type: any;
  @Input() event: any;

  evento;


  constructor(
    private modalCtrl: ModalController,
    private api: ApiService,
    private loading: LoadingService,
    private toast: ToastService
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

  ionViewWillEnter() {

  
    if (this.type == 2) {
      this.evento = this.list.filter((item) => item.GUID == this.event.id).length > 0 ? this.list.filter((item) => item.GUID == this.event.id)[0] : false;

    } else  if(this.type == 1){
      const hoy = moment().format('YYYY-MM-DD')

      this.list = this.list.filter((item) => {
        return moment(item.programado).format('YYYY-MM-DD') >= hoy
      })

      this.list = _.sortBy(this.list, 'programado')



      console.log(this.list);
  
    }  else  if(this.type == 3){
      this.evento = this.event.id

      console.log(this.event);
  
    } else  if(this.type == 4){
      this.evento = this.event.id

      console.log(this.event);
  
    } 



  }

  async guardar() {

    this.loading.createLoading('Cerrando tarea')

    this.api.updateSurvey( {
      AccessToken: this.tkn,
      UserGUID: this.evento.AssignedToGUID,
      Duration: '60',
      SearchBy: 'ActivityID',
      SearchValue: this.evento.ID,
      Values: JSON.stringify([ {
        apiId: 'REALIZADO',
        Value: 'SI'
      }])
  }).subscribe((data) => {

    if (data) {

      this.api.changeDispath({
        tkn: this.tkn,
        user: this.evento.AssignedToGUID,
        status: '5F1B94C0-812A-4B69-A352-8BDB66978E5B',
        date: moment().add(2, 'minutes').format('YYYY-MM-DD HH:mm'),
        guid: this.evento.GUID
      }).subscribe((dat: any) => {

        this.toast.newCreatedToast('El proceso se finalizò correctamente', true);
        this.loading.cancelLoading();
       this.modalCtrl.dismiss({close: true})

      }, (err) => {
        this.toast.newCreatedToast('No se actualizò el formulario', false);
        this.loading.cancelLoading();

      })

    } else {

      this.toast.newCreatedToast('Se creo la orden pero no se actualizò el formulario', false);
      this.loading.cancelLoading();

    }


   

    }, (err) => {
      this.toast.newCreatedToast('Se creo la orden pero no se actualizò el formulario', false);
      this.loading.cancelLoading();
    })

  }
}