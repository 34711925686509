import { UserRegister } from './../../Interfaces/user.interface';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DataComparnyService } from 'src/app/Services/dataCompany.service';
import { NavController, MenuController } from '@ionic/angular';

import { ToastService } from 'src/app/Services/Utilities/Toast.service';
import { ApiService } from 'src/app/Services/Api/Api.service';
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { StorageWebService } from 'src/app/Services/Storage/StorageWeb.service';
import { ApiEntrenaService } from 'src/app/Services/Api/ApiEntrena.service';
import { environments } from '../../../environments/environment.prod';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements AfterViewInit {

  dataCompany: any = {
    pathLogoCompany: false,
    nameCompany: false
  };

  view = '0';
  users = [];
  inter = [];
  exter = [];
  ext = false;

  // ENTRENA 

  role = '';

  logo = '';
  name = 'Visitrack Application';

  constructor(
    private dataCompanyService: DataComparnyService,
    private navCtrl: NavController,
  
    private toasService: ToastService,
    private apiService: ApiService,
    private apiEntrena: ApiEntrenaService,
    private router: Router,
    private socket: Socket,
    private storage: StorageWebService,
    private menuCtrl: MenuController
  ) {

    this.dataCompanyService.dataCompany.subscribe((data: any) => {
      console.log(data, 'data menu')
      if (data.logo || data.name) {

        if (data.logo) {
          this.logo = data.logo;
        }
        
        this.name = data.name;
      }
     // this.ext = data.ext ? data.ext : false;
    });

    this.dataCompanyService.$view.subscribe(async (view) => {
      this.view = view;
      if (view === '2') {
        const login = await this.storage.getLogin();

        if (login) {
          this.apiService.getConnections(login[0].CompanyID).subscribe((connections: any) => {
            this.users = connections.response;
            this.inter = this.users.filter((item) => {
              return item.type === 'inter';
            });
    
            this.exter = this.users.filter((item) => {
              return item.type === 'exter';
            });
          });
       }
      } else if (view === '3') {
        const login = await this.storage.getLogin();

        if (login) {
          if (login[0].typeApp === '2') {
            this.role = login[0].data.role;
          }
       }
      }
    });

    this.dataCompanyService.$users.subscribe((arrConnections: any) => {
        this.users = arrConnections.connections;
    });


  }

  async ngAfterViewInit() {
   // this.socket.connect();
   this.menuCtrl.enable(false, 'menu');
   const login = await this.storage.getLogin();

   if (login) {
       console.log('MENU', login[0])
       if (login[0].typeApp === '2') {
          this.role = login[0].data.role;
          this.loadDataMenu( login[0])
       } else if (login[0].typeApp === '1') {
        this.ext = login[0].type === 'exter' ? true : false;
        this.apiService.getConnections(login[0].CompanyID).subscribe((connections: any) => {
         this.users = connections.response;
         this.inter = this.users.filter((item) => {
           return item.type === 'inter'
         });
 
         this.exter = this.users.filter((item) => {
           return item.type === 'exter'
         });
       });
       }
   }

  }

  loadDataMenu(login) {

    let user = '';

    if (login.data.role === 'USER_ENTRENA') {
      user = login.data.parentAdmin;
    } else {
      user = login.data._id;
    }


    this.apiEntrena.preferencesLoad({user}, login.token).subscribe({next: (data: any) => {
      console.log(data, 'todo')
      if (data.status === 'ok') {

        if (data.response) {

          console.log('LOGOG', data.response)

        //  this.logo = data.response.logo ? environments.URL_NODE + '/uploads/Perfiles/' +  data.response.logo : '';
       
          const theme = data.response.theme ? data.response.theme : 'light';
          this.dataCompanyService.setColor( theme);
          this.dataCompanyService.getDataCompany({
           logo: data.response.logo && data.response.logo.includes('https://s3.amazonaws.com') ?  data.response.logo : environments.URL_NODE + '/uploads/Perfiles/' +  data.response.logo,
            name: data.response.nameCompany ? data.response.nameCompany : 'VTEntrena Application'
          })

        } else {
          this.logo = '';
          this.name = 'VTEntrena Application'
        }
         
         
        } else {
          this.logo = '';
          this.name = 'VTEntrena Application'
        }
    }, error: (err) => {
      this.logo = '';
      this.name = 'VTEntrena Application'
  }} )

  }




  navigate(direction: number) {
      switch(direction) {
        case 1:
          this.router.navigate(['/forms']);
          this.dataCompanyService.getVista('1');
          break;

        case 2:
            console.log('Ubicaciones')
            break;

        case 3:
            this.router.navigate(['/home']);
            break;

        case 4:
            console.log('Configuracion')
            break;

        case 5:
            this.router.navigate(['/repository'])
            break;

        case 5:
            this.router.navigate(['/render'])
            break;

        case 6:
            this.storage.getLogin().then((data) => {
              console.log(data[0], 'saliendo');
              if (data[0].typeApp === '2') {
               this.apiEntrena.LogoutUser(data[0].data, data[0].token).subscribe((res: any) => {
                console.log('Bien')
                if (res.status === 'ok') {
                    this.socket.emit('exitVT', data[0].data);
                  }
               })
              } else if (data[0].typeApp === '1') {
                this.socket.emit('salir', data[0]);
              }
              
              this.storage.closeSession().then(() => {
                this.router.navigate(['/login']);
              });
            })
            break;
        
        case 11:
            this.router.navigate(['/admin-users'])
            break;

            case 12:
              this.router.navigate(['/preferences'])
              break;
  
         case 13:
            this.router.navigate(['/designer-list'])
            break;

         case 14:
            this.router.navigate(['/list-my-designs'])
            break;


 
     
      }
  }

  chat() {
    
  }




}
