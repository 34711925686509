import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './guards/login.guard';
import { FormsGuard } from './guards/forms.guard';
import { EntrenaGuard } from './guards/entrena.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./Pages/home/home.module').then( m => m.HomePageModule), canActivate: [FormsGuard]},
  {
    path: 'login',
    loadChildren: () => import('./Pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'forms',
    loadChildren: () => import('./Pages/central/central.module').then( m => m.CentralPageModule),
    canActivate: [FormsGuard]
  },
  {
    path: 'form',
    loadChildren: () => import('./Pages/form/form.module').then( m => m.FormPageModule),
    canActivate: [FormsGuard]
  },
  {
    path: 'repository',
    loadChildren: () => import('./Pages/repository/repository.module').then( m => m.RepositoryPageModule),
    canActivate: [FormsGuard]
  },
  {
    path: 'repository/folders/:parent',
    loadChildren: () => import('./Pages/folders/folders.module').then( m => m.FoldersPageModule),
    canActivate: [FormsGuard]
  },
  {
    path: 'render',
    loadChildren: () => import('./Pages/render/render.module').then( m => m.RenderPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./Pages/messages/messages.module').then( m => m.MessagesPageModule),
    canActivate: [FormsGuard]
  },
  {
    path: 'init',
    loadChildren: () => import('./Pages/Entrena/init/init.module').then( m => m.InitPageModule),
    canActivate: [EntrenaGuard]
  },
  {
    path: 'admin-users',
    loadChildren: () => import('./Pages/Entrena/admin-users/admin-users.module').then( m => m.AdminUsersPageModule),
    canActivate: [EntrenaGuard]
  },
  {
    path: 'designer-list',
    loadChildren: () => import('./Pages/Entrena/Designer/designer-list/designer-list.module').then( m => m.DesignerListPageModule),
    canActivate: [EntrenaGuard]
  },
  {
    path: 'designer/:id',
    loadChildren: () => import('./Pages/Entrena/Designer/designer/designer.module').then( m => m.DesignerPageModule),
    canActivate: [EntrenaGuard]
  },
  {
    path: 'list-my-designs',
    canActivate: [EntrenaGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./Pages/Entrena/Designer/list-my-designs/list-my-designs.module').then( m => m.ListMyDesignsPageModule),

      },
      {
        path: 'comments/:id',
        loadChildren: () => import('./Pages/Entrena/comments/comments.module').then( m => m.CommentsPageModule)
      }
    ]
  },
  {
    path: 'view-design/:id',
    loadChildren: () => import('./Pages/Entrena/Designer/view-design/view-design.module').then( m => m.ViewDesignPageModule),
    canActivate: [EntrenaGuard]
  },
  {
    path: 'logs/:id',
    loadChildren: () => import('./Pages/Entrena/logs/logs.module').then( m => m.LogsPageModule)
    ,
    canActivate: [EntrenaGuard]
  },
  {
    path: 'preferences',
    loadChildren: () => import('./Pages/Entrena/preferences/preferences.module').then( m => m.PreferencesPageModule)
  },
  {
    path: 'interfaces/nettsegur',
    loadChildren: () => import('./Pages/interfaces/nett-segur/nett-segur.module').then( m => m.NettSegurPageModule)
  },
  {
    path: 'interfaces/cdosupervisores',
    loadChildren: () => import('./Pages/interfaces/cdosupervisores/cdosupervisores.module').then( m => m.CdosupervisoresPageModule)
  },
  {
    path: 'interfaces/agilissa-calendario',
    loadChildren: () => import('./Pages/interfaces/agilissa/agilissa.module').then( m => m.AgilissaPageModule)
  },
  {
    path: 'interfaces/oszford-calendario',
    loadChildren: () => import('./Pages/interfaces/oszford-calendario/oszford-calendario.module').then( m => m.OszfordCalendarioPageModule)
  },
  {
    path: 'interfaces/diamante-mtto',
    loadChildren: () => import('./Pages/interfaces/diamante-mtto/diamante-mtto.module').then( m => m.DiamanteMttoPageModule)
  },
  {
    path: 'interfaces/biomedicos',
    loadChildren: () => import('./Pages/interfaces/biomedicos/biomedicos.module').then( m => m.BiomedicosPageModule)
  },
  {
    path: 'interfaces/inverpack/mantenimientos',
    loadChildren: () => import('./Pages/interfaces/inverpack/mantenimientos/mantenimientos.module').then( m => m.MantenimientosPageModule)
  },
  {
    path: 'interfaces/asignacion-diamante',
    loadChildren: () => import('./Pages/interfaces/asignacion-diamante/asignacion-diamante.module').then( m => m.AsignacionDiamantePageModule)
  },
  {
    path: 'interfaces/brillantex',
    loadChildren: () => import('./Pages/interfaces/brillantex/brillantex.module').then( m => m.BrillantexPageModule)
  },
  {
    path: 'interfaces/cronograma-sst',
    loadChildren: () => import('./Pages/interfaces/cdo/cronograma-sst/cronograma-sst.module').then( m => m.CronogramaSSTPageModule)
  },
  {
    path: 'interfaces/seguimiento-sst',
    loadChildren: () => import('./Pages/interfaces/cdo/seguimiento-sst/seguimiento-sst.module').then( m => m.SeguimientoSSTPageModule)
  },
  {
    path: 'interfaces/laboratorio/revision',
    loadChildren: () => import('./Pages/interfaces/laboratorio/revision/revision.module').then( m => m.RevisionPageModule)
  },

  {
    path: 'interfaces/circulo',
    loadChildren: () => import('./Pages/interfaces/circulo/circulo.module').then( m => m.CirculoPageModule)
  },

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
