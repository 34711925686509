import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diamantemtto'
})
export class FiltroDiamanteMttoPipe implements PipeTransform {

  transform(value: any[], filtroby: string, all?: Boolean) {

    filtroby = filtroby.toLowerCase()

    if (all) {
      return filtroby == '' ? value :  value.filter((item)  => {
        let val = item.Name.toLowerCase();
        if (val.includes(filtroby)) {
            return item
        }
    });
    } else {
      return filtroby == '' ? [] :  value.filter((item)  => {
        let val = item.Name.toLowerCase();
        if (val.includes(filtroby)) {
            return item
        }
    });
    }


   
  }

}
