import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'underscore'
import * as moment from 'moment-timezone'
import { ApiService } from 'src/app/Services/Api/Api.service';

@Component({
  selector: 'app-controlcdoasistencial',
  templateUrl: './controlcdoasistencial.component.html',
  styleUrls: ['./controlcdoasistencial.component.scss'],
})
export class ControlcdoasistencialComponent implements OnInit {

  @Input() padres;
  @Input() data; 
  @Input() tkn; 


  revisados = [];

  tabla = [];
  txt = '';

  constructor(
    private modalCtrl: ModalController,
    private api: ApiService
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {

  

    _.each(this.padres, (dat, i) => {
      const data = this.data.filter((item) => item.ParentGUID == dat)
      console.log(data);

      const verdes = data.filter((item) => item.CompanyStatusName == 'Completado')
      const rojos = data.filter((item) => item.CompanyStatusName == 'PENDIENTE ASEO')
      const amarillos = data.filter((item) => item.CompanyStatusName == 'EN PROCESO DE ASEO')
      const grises = data.filter((item) => item.CompanyStatusName == 'CANCELADO POR DEMORA')
      const azules = data.filter((item) => item.CompanyStatusName == 'ELIMINADA POR LIMPIEZA PREVIA A SOLICITUD')

      
  

      this.tabla.push({
        padre: dat,
        data: data,
        hab: data.length > 0 ? data[0].LocationName : '',
        cam: data.length > 0 ? data[0].AssetName : '',
        rojos: rojos.length,
        amarillos: amarillos.length,
        grises: grises.length,
        verdes: verdes.length,
        azules: azules.length,
        ldg: false
      })
    })

  }

  buscar(event) {
     this.txt = event.detail.value;
  }
 


  cambio(data) {

  
    return new Promise((resolve, reject) => {
      this.api.changeStatus({
        tkn: this.tkn,
        guid: data.GUID,
        user: data.AssignedToGUID,
        status: 'FA3A75FC-596D-4A8E-923B-17D7E6BBA5F5'
      }).subscribe((data: any) => {
        resolve(true)
      })
    })
  }

  async eliminar(item, i) {
    console.log(item)
    const indx = this.tabla.findIndex((val) => val.padre == item.padre);

    this.tabla[indx].ldg = true;


    let arr = [];
    
    for (const dat of item.data) {
      await this.cambio(dat)
      this.tabla[indx].rojos -= 1;
      arr.push(true)
    }

    await this.cambio({
      GUID: item.padre,
      AssignedToGUID: item.data[0].AssignedToGUID
    })

    this.revisados.push(item.padre)
    
    this.tabla[indx].azules = arr.length;
    this.tabla[indx].ldg = false;
    
  }

  unicos(arr, item) {
    return _.uniq(_.map(arr, (dat) => dat[item]))
  }

  cerrar() {

    if (this.revisados.length > 0) {
      this.modalCtrl.dismiss({
        revisados: this.revisados
      });

    } else {
      this.modalCtrl.dismiss();
    }
    
  }

}
