
import { Component } from '@angular/core';

import { Platform, MenuController } from '@ionic/angular';

import { Storage } from '@ionic/storage';
import { DataComparnyService } from './Services/dataCompany.service';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private storage: Storage,

    private dataCompany: DataComparnyService,
    private socket: Socket,
    private router: Router,
    private menuCtrl: MenuController
  ) {
   // this.initializeApp();
  }

  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    console.log('Hola')
    
    await this.storage.create();
    this.initializeApp();
  }


  initializeApp() {
    this.platform.ready().then(() => {
 
      const platforms = this.platform.platforms();

      // if (platforms.includes('desktop')) {
      this.createdCollections().then(() => {
          console.log('Creado');
       //   this.myPlatform.changePlatform('web');
        }).catch((err) => {
          console.log('Error ', err);
        });
      // }
    });
  }

  async createdCollections() {
    console.log('Holaaa')
    const login = await this.storage.get('login');
    const forms = await this.storage.get('forms');
    const formsActive = await this.storage.get('formActive');
    const chats = await this.storage.get('chats');
    const logininterface = await this.storage.get('logininterface');

    if (!login) {
      await this.storage.set('login', []);
    } else {
      if (login.length > 0) {
        if (login[0].typeApp === '2') {
          console.log('DATA VISTA', login[0].logo)
          this.socket.emit('connectVT', login[0].data)
          this.menuCtrl.enable(true, 'menu');
          this.dataCompany.getVista('3');
          this.dataCompany.getDataCompany({
            pathLogoCompany: login[0].logo ? login[0].logo : '',
            nameCompany: login[0].companyName
          });

        } else if (login[0].typeApp === '1') {

          this.socket.emit('welcome', (login[0]));
          this.socket.emit('login', {ID: login[0].UserID});
          this.dataCompany.getDataCompany({
            pathLogoCompany: login[0].logo,
            nameCompany: login[0].companyName,
            ext: login[0].type === 'exter' ? true : false
          });

        }
      }
    }

    if (!forms) {
      await this.storage.set('forms', []);
    }

    if (!formsActive) {
      await this.storage.set('formActive', []);
    }

    if (!chats) {
      await this.storage.set('chats', []);
    }
    if (!logininterface) {
      await this.storage.set('logininterface', []);
    }

  }
}
