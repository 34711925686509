import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { ApiService } from 'src/app/Services/Api/Api.service';
import { LoadingService } from 'src/app/Services/Utilities/Loading.service';
import { ToastService } from 'src/app/Services/Utilities/Toast.service';

@Component({
  selector: 'app-usarios-inver',
  templateUrl: './usarios-inver.component.html',
  styleUrls: ['./usarios-inver.component.scss'],
})
export class UsariosInverComponent implements OnInit {

  @Input() tkn;
  @Input() acti: any;

  prioridad;
  tecnico;

  constructor(
    private modalCtrl: ModalController,
    private api: ApiService,
    private loading: LoadingService,
    private toast: ToastService
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

  ionViewWillEnter() {
    this.loading.cancelLoading()
  }


  createOnlyActivity(guid, prioridad, tec) {

    return new Promise((resolve, reject) => {
      const solicitud = this.acti.Values.filter((item) => item.apiId == 'solicitud')

      this.api.aceptActivity({
        AccessToken: this.tkn,
        FormGUID: '76E93F88-7612-466E-BBD7-3C95A7679D6D',
        LocationGUID: this.acti.LocationGUID,
        AssetGUID: this.acti.AssetGUID,
        UserGUID: guid,
        Duration: "60",
        DispachDateTime: moment().add(2, 'minutes').format('YYYY-MM-DD HH:mm'),
        Values: JSON.stringify([{
          apiId: 'ENCONTRO',
          Value: solicitud.length > 0 ? solicitud[0].Value : ''
        },{
          apiId: 'TIPOMTO',
          Value: 'CORRECTIVO'
        }, {
          apiId: 'PRIORIDAD',
          Value: prioridad
        }, {
          apiId: 'TECNICO',
          Value: tec
        }]),
        ActivityGUID: '',
        ParentGUID:this.acti.GUID,
        CompanyStatusGUID: '0E7DA2A2-14A4-4B15-A4DC-4BFE9C9BCDEB'
      }).subscribe(async (dat: any) => {
        console.log(dat)
      
        if (dat.Status === 'OK') {

          resolve(true)

        } else {
          resolve(false)
        }
      }, (err) => {
        resolve(false)
      })
    })

  }

  async assign () {

    this.loading.createLoading('Creando Orden')
    console.log(this.tecnico)

    const orden = await this.createOnlyActivity(this.tecnico.guid, this.prioridad, this.tecnico.name )

   if (orden) {

    this.api.updateSurvey( {
      AccessToken: this.tkn,
      UserGUID: this.tecnico.guid,
      Duration: '60',
      SearchBy: 'ActivityID',
      SearchValue: this.acti.ID,
      Values: JSON.stringify([ {
        apiId: 'PRIORIDAD',
        Value: this.prioridad
      }, {
        apiId: 'TECNICO',
        Value: this.tecnico.name
      }])
  }).subscribe((data) => {

    if (data) {

      this.api.changeDispath({
        tkn: this.tkn,
        user: this.tecnico.guid,
        status: '6E7AF6A1-E2A6-4DA1-8BB1-21093261287C',
        date: moment().add(2, 'minutes').format('YYYY-MM-DD HH:mm'),
        guid: this.acti.GUID
      }).subscribe((dat: any) => {

        this.toast.newCreatedToast('El proceso se finalizò correctamente', true);
        this.loading.cancelLoading();
       this.modalCtrl.dismiss({close: true})

      }, (err) => {
        this.toast.newCreatedToast('Se creo la orden pero no se actualizò el formulario', false);
        this.loading.cancelLoading();

      })

    } else {

      this.toast.newCreatedToast('Se creo la orden pero no se actualizò el formulario', false);
      this.loading.cancelLoading();

    }


   

    }, (err) => {
      this.toast.newCreatedToast('Se creo la orden pero no se actualizò el formulario', false);
      this.loading.cancelLoading();
    })

   } else {
    this.loading.cancelLoading();
    this.toast.newCreatedToast('No se pudo crear la orden', false);
    this.close()
    
   }
  }

  cambiar(event, type) {
    if (type == 1) {
      if ( event.detail.value) {
          this.prioridad = event.detail.value;
      }
    
    } else {
      if (event.detail.value) {
        this.tecnico = event.detail.value;
      }
    }

    console.log(this.prioridad, this.tecnico)
  }
}
