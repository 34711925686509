import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-numeric-value',
  templateUrl: './numeric-value.component.html',
  styleUrls: ['./numeric-value.component.scss'],
})
export class NumericValueComponent implements OnInit {

  @Input() lab;
  @Input() required;
  @Input() hel;
  @Input() pho;
  @Input() readonly;
  constructor() { }

  ngOnInit() {
    if (this.pho === '') {
      this.pho = 'Digitar valor';
    }
  }

}
