import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss'],
})
export class RadioButtonsComponent implements OnInit {
  @Input() lab;
  @Input() required;
  @Input() hel;
  @Input() readonly;
  @Input() opts;

  constructor() { }

  ngOnInit() {
    console.log(this.opts, typeof this.opts);
  }

}
