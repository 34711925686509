import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'agilissacliente'
})
export class FiltroAgilissaPipe implements PipeTransform {

  transform(value: any[], filtroby: string, byproperty?: any) {

    filtroby = filtroby.toLowerCase()

    if (byproperty) {

      return filtroby == '' ? value :  value.filter((item: any)  => {
        let val = item[byproperty].toLowerCase();
        if (val.includes(filtroby)) {
            return item
        }
    });

    } else {
      return filtroby == '' ? [] :  value.filter((item)  => {
        let val = item.toLowerCase();
        if (val.includes(filtroby)) {
            return item
        }
    });
    }


    
  }

}
