import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment-timezone';
import { ApiService } from 'src/app/Services/Api/Api.service';

@Component({
  selector: 'app-carga-task',
  templateUrl: './carga-task.component.html',
  styleUrls: ['./carga-task.component.scss'],
})
export class CargaTaskComponent implements OnInit {
 
  @Input() tkn;
  @Input() type;
  @Input() executes;
  @Input() from;
  @Input() mode;
  @Input() users;
  @Input() userGUID;
  @Input() data: any;
  @Input() ejecutadas: any;

  load = true;

  list = [];

  list2 = [];

  actual;
  mes =  '';
  mesMuestra = '';
  semana = '';

  procesar;

  carga;
  userSelect;
  executesTask = [];

  cont = 0;

  


  constructor(
    private modalCtrl: ModalController,
    private api: ApiService
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

  ionViewWillEnter() {

   if (!this.type) {
    this.loadActivities()
   } 
   moment.locale('es')

   if (this.mode == 'week') {
    this.mes = moment(this.from).format('YYYY-MM-DD HH:mm');
   } else {
    this.mes = moment(this.from).format('YYYY-MM');
   }

   this.actual = moment(this.from).format('YYYY-MM');
   this.mesMuestra = moment(this.mes).format('MMMM YYYY');
   console.log(this.users)

  }

   select(event) {
    this.userSelect = event.detail.value;
    this.executesTask = this.executes.filter((it) => it.AssignedToGUID == this.userGUID)
    console.log(this.executesTask, this.userSelect)
  }

  async processReassgined() {
    this.carga = true;
    for (const dat of this.executesTask) {
        const resp = await this.reassing(dat.GUID, this.userSelect, moment(dat.DispatchDateTime).format('YYYY-MM-DD HH:mm:ss'))

        if (resp) {
          this.list.push(1)
        }
    }

    this.carga = false;
    this.modalCtrl.dismiss({})

  }

  prev() {
    moment.locale('es')
    if (this.mode == 'week') {
      this.mes = moment(this.mes).subtract(1, 'weeks').format('YYYY-MM-DD HH:mm')
      this.semana = 'Desde el ' + moment(this.mes).format('LL')
      this.cont -= 1;
    } else {
      this.mes = moment(this.mes).subtract(1, 'months').format('YYYY-MM')
      this.mesMuestra = moment(this.mes).format('MMMM YYYY')
  
    }
  }

  next() {
    moment.locale('es')

    if (this.mode == 'week') {
      this.cont += 1;
      this.mes = moment(this.mes).add(1, 'weeks').format('YYYY-MM-DD HH:mm')
      console.log(this.mes)
      this.semana = 'Desde el ' + moment(this.mes).format('LL')
  
    } else {
      this.mes = moment(this.mes).add(1, 'months').format('YYYY-MM')
      this.mesMuestra = moment(this.mes).format('MMMM YYYY')
  
    }

  
  }

  async process() {



    let diff = moment(this.mes).diff(moment(this.actual), 'months');;

   


    this.carga = true;

    for (const acivity of this.executes) {

      let programacion = '';

      if (this.mode == 'week') {
        programacion =  moment(acivity.DispatchDateTime).add(this.cont * 7, 'days').format('YYYY-MM-DD HH:mm:ss');
      } else if (this.mode == 'month') {
        programacion =  moment(acivity.DispatchDateTime).add(diff, 'months').format('YYYY-MM-DD HH:mm:ss');
      }
      
      const sector = acivity.Values.filter((it) => it.apiId == 'SECTOR')
      const tipo = acivity.Values.filter((it) => it.apiId == 'TAREA')
    
   
      const resp = await this.createOnlyActivity({
        loc: acivity.LocationGUID,
        ass: acivity.AssetGUID,
        user: acivity.AssignedToGUID,
        dur: acivity.DurationMins,
        start: programacion,
        sector: sector.length > 0 ? sector[0].Value : '' ,
        tipo: tipo.length > 0 ? tipo[0].Value : '' ,
        area: acivity.area,
        subarea: acivity.subarea ,
        tipoarea: acivity.tipoarea,
        tipozona: acivity.tipozona ,
        ubicacion: acivity.ubicacion ,
        estructura: acivity.estructura ,
        piso: acivity.piso ,
        nmc: acivity.nmc 
      }, true)

      if (resp) {
        this.list.push(1)
      }
    }

    this.carga = false;

    this.modalCtrl.dismiss()

  }

  async loadActivities() {

    if (this.ejecutadas.length > 0) {

      for (const element of this.ejecutadas) {
          await this.change(element.guid, element.date)

          this.list2.push(element);
      }

    }


    for (const item of this.data.task) {
      const resp: any = await this.createOnlyActivity(item);

      if (resp) {
        item.GUID = resp.AcitivyGUID ? resp.AcitivyGUID : '';

        this.list.push(item)

      } 

      
    }


    this.modalCtrl.dismiss(this.list)

  }

  createOnlyActivity( data, valid?) {
    console.log(data)

    return new Promise((resolve, reject) => {

      let tarea  = '';

      if (valid) {
        tarea = data.tipo;
      } else {
        if (data.tipo == 'terminal') {
        tarea = '04. Terminal'
      } else if (data.tipo == 'rutinario') {
        tarea = '02. Rutinaria'
      } else if (data.tipo == 'mantenimiento') {
        tarea = '03. Mantenimiento'
      }
      }

      
    
      console.log({
        AccessToken: this.tkn,
        FormGUID: '2004307A-B1CF-4109-97D1-8603A63BA6E9',
        LocationGUID: data.loc,
        AssetGUID: data.ass,
        UserGUID: data.user,
        Duration: "60",
        DispachDateTime: moment(data.start).format('YYYY-MM-DD HH:mm'),
    
        Values: JSON.stringify([{
          apiId: 'SECTOR',
          Value: data.sector
        }, {
          apiId: 'TAREA',
          Value: tarea
        }, {
          apiId: 'AREA',
          Value: data.area
        }, {
          apiId: 'TIPODEAREA',
          Value: data.tipoarea
        }, {
          apiId: 'SUB_AREA',
          Value: data.subarea
        }, {
          apiId: 'TIPO_ZONA',
          Value: data.tipozona
        }, {
          apiId: 'TIPO_ESTRUCTURA',
          Value: data.estructura
        }, {
          apiId: 'UBICACION',
          Value: data.ubicacion
        }, {
          apiId: 'PISO',
          Value: data.piso
        }, {
          apiId: 'NMC',
          Value: data.nmc
        }]),
        ActivityGUID: '',
        CompanyStatusGUID: 'CA1B6E82-15F9-458C-B6A1-D988C7194C76'
      })

      if (data.dur) {

        this.api.aceptActivity({
          AccessToken: this.tkn,
          FormGUID: '2004307A-B1CF-4109-97D1-8603A63BA6E9',
          LocationGUID: data.loc,
          AssetGUID: data.ass,
          UserGUID: data.user,
          Duration: data.dur.toString(),
          DispachDateTime: moment(data.start).format('YYYY-MM-DD HH:mm'),
          Values: JSON.stringify([{
            apiId: 'SECTOR',
            Value: data.sector
          }, {
            apiId: 'TAREA',
            Value: tarea
          }, {
            apiId: 'AREA',
            Value: data.area
          },{
            apiId: 'TIPODEAREA',
            Value: data.tipoarea
          }, {
            apiId: 'SUB_AREA',
            Value: data.subarea
          }, {
            apiId: 'TIPO_ZONA',
            Value: data.tipozona
          }, {
            apiId: 'TIPO_ESTRUCTURA',
            Value: data.estructura
          }, {
            apiId: 'UBICACION',
            Value: data.ubicacion
          }, {
            apiId: 'PISO',
            Value: data.piso
          }, {
            apiId: 'NMC',
            Value: data.nmc
          }]),
          ActivityGUID: '',
          CompanyStatusGUID: 'CA1B6E82-15F9-458C-B6A1-D988C7194C76'
        }).subscribe(async (dat: any) => {
          console.log(dat)
        
          if (dat.Status === 'OK') {
  
            resolve(dat)
  
          } else {
            resolve(false)
          }
        }) 

      } else {
        resolve(true)
      }

     

    })
  }

  change( guid, date ) {


    return new Promise((resolve, reject) => {

      console.log({
        tkn: this.tkn,

        guid
    
    
      })

        this.api.changeUser({
          tkn: this.tkn,

          date,
          guid
      
      
        }).subscribe(async (dat: any) => {
          console.log(dat)
        
          resolve(dat)
        }, () =>  resolve(true)) 

  

    })
  }


  reassing( guid, user, date ) {


    return new Promise((resolve, reject) => {

      console.log({
        tkn: this.tkn,
        user,
        guid
    
    
      })

        this.api.changeUser({
          tkn: this.tkn,
          user,
          date,
          guid
      
      
        }).subscribe(async (dat: any) => {
          console.log(dat)
        
          resolve(dat)
        }, () =>  resolve(true)) 

  

    })
  }


  save() {

  }

}
