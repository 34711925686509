import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-circulocerrado',
  templateUrl: './circulocerrado.component.html',
  styleUrls: ['./circulocerrado.component.scss'],
})
export class CirculocerradoComponent implements OnInit {

  constructor(
    private popover: PopoverController
  ) { }

  ngOnInit() {}

  exit() {
    this.popover.dismiss(1)
  }

}
