import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrogeneralcdo'
})
export class FiltroGeneralCDOpIpe implements PipeTransform {

  transform(value: any[], filtroby: string) {
    console.log(value, 'valor')

    filtroby = filtroby.toLowerCase()

    

    return filtroby == '' ? value : value.filter((item) => {
        let loc = item.loc.toLowerCase();
        let asset = item.ass.toLowerCase();
        let fec = item.fec.toLowerCase();
   
        return loc.includes(filtroby) || asset.includes(filtroby) || fec.includes(filtroby)
    } );
  }

}
