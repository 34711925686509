import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {

  transform(value: any[], api: string) {
    console.log(value, 'valor')
    return value.filter((item) => item.apiId == api).length > 0 ? value.filter((item) => item.apiId == api)[0].Value : '---';
  }

}
