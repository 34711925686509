import { Component, Input, OnInit } from '@angular/core';
import { IonItem, ModalController } from '@ionic/angular';
import { truncate } from 'fs';
import { ApiService } from 'src/app/Services/Api/Api.service';
import { LoadingService } from 'src/app/Services/Utilities/Loading.service';
import { ToastService } from 'src/app/Services/Utilities/Toast.service';

@Component({
  selector: 'app-users-diamante',
  templateUrl: './users-diamante.component.html',
  styleUrls: ['./users-diamante.component.scss'],
})
export class UsersDiamanteComponent implements OnInit {

  @Input() tkn;
  @Input() type;
  @Input() data;
  @Input() view;

  users = [];
  carga;

  text = '';
  text2 = '';
  fecha;

  locs = [];
  lists = [];
  listSelect;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private modal: ModalController,
    private loading: LoadingService
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {

    console.log(this.data)
    
    if (this.view == 1) {
        this.api.getUsers(this.tkn).subscribe((data: any) => {
              console.log(data, 'usuarios')
              if (data.length > 0) {
                this.users = data.filter((item) => item.Active == 'True');
              } else {
                  this.users = [];
              }

              this.carga = true;
            
              
            }, () => this.carga = true)
    } else {

      this.api.getLocations({AccessToken: this.tkn, LocationTypeID: '73E2C37A-5570-4134-BDCA-5C394167F877'}).subscribe(async (data: any) => {
       
        if (data.length > 0) {
          this.locs = data;
        } else {
            this.locs = [];
        }

        console.log(this.locs)

        this.lists = await this.getList();

        console.log(this.lists)

        this.carga = true;
      
        
      }, () => this.carga = true)

    }

   

  }

  async getList() : Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.getLists({AccessToken: this.tkn, ListTypeGUID: 'zvIHQh7Kcj'}).subscribe((data: any) => {
       
     
         resolve(data)
        
      }, () => resolve([]))
    })
  }

  filter(event) {
    this.text = event.detail.value;
  }
  filter2(event) {
    this.text2 = event.detail.value;
  }

  created(user) {
    // sA4XrNXEmV prev
    // BF39EE15-4D86-4C07-B1C1-D967AAB22C34 corre

    if (this.listSelect) {

      this.loading.createLoading('Añadiendo ruta a centro de costo')


      this.api.createLocation({
        tkn: this.tkn,
        LocationTypeGUID: '73E2C37A-5570-4134-BDCA-5C394167F877',
        Name: user.Name
      }).subscribe(async (dat: any) => {
        console.log(dat)
      
        if (dat.Status === 'OK') {

         await  this.loading.cancelLoading();

         await  this.modal.dismiss()
        await   this.toast.newCreatedToast('Centro de costo editado', false)

         

        } else {
         await  this.loading.cancelLoading();

          this.toast.newCreatedToast('No se pudo editar el centro de costo', false)
     
        }
      
    })

    } else{
      this.toast.newCreatedToast('Debe seleccionar una ruta', false)
    }

  }



  createOnlyActivity(user) {


    if (!this.fecha) {
      this.toast.newCreatedToast('Debe seleccionar una fecha', false)
      return;
    }

    this.loading.createLoading('Creando actividad, por favor espere')
   

      this.api.aceptActivity({
        AccessToken: this.tkn,
        FormGUID: this.type == 'prev' ? 'sA4XrNXEmV' :  'BF39EE15-4D86-4C07-B1C1-D967AAB22C34',
        LocationGUID: this.data.LocationGUID,
        AssetGUID: this.data.AssetGUID,
        UserGUID: user.ID,
        Duration: "60",
        DispachDateTime: this.fecha + ' 00:00:00',
        Values: JSON.stringify([]),
        ActivityGUID: '',
        CompanyStatusGUID: '5F9C20B3-8B41-4D7B-B967-4BC4D3FF9854'
      }).subscribe(async (dat: any) => {
        console.log(dat)
      
        if (dat.Status === 'OK') {

          await this.loading.cancelLoading()

          this.modal.dismiss();
          this.toast.newCreatedToast('La actividad se creó correctamente', false)

        } else {
          await this.loading.cancelLoading()

          this.toast.newCreatedToast('La actividad no se pudo crear', false)
          this.modal.dismiss();
        }
      }, async (err) => {
        await this.loading.cancelLoading()
        this.toast.newCreatedToast('La actividad no se pudo crear', false)
        this.modal.dismiss();
      })


  }



  select(event) {
    this.listSelect = event.Name;
    this.text2 = '';
  }

   close() {
    this.modal.dismiss()
  }

}
