import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {

  @Input() lab;
  @Input() required;
  @Input() hel;
  @Input() readonly;
  @Input() opts;

  constructor() { }

  ngOnInit() {
    console.log(this.opts, typeof this.opts);
  }

}
