import { NgModule } from '@angular/core';
import { FilterRepositoryPipe } from './Filters/filter-repository.pipe';
import { UsersPipe } from './Filters/users.pipe';
import { FilterUserRepositoryPipe } from './Filters/filterUserRepo.pipe';
import { SearchVTEntrena } from './VTEntrena/users.pipe';
import { ValuesPipe } from './Filters/valores.pipe';
import { FiltroBuscarPipe } from './Filters/filtroBuscar.pipe';
import { FiltroCDOPipe } from './Filters/filtroCDO.pipe';
import { FiltroGeneralCDOpIpe } from './Filters/filtroGeneralCDO.pipe';
import { FiltroPadrePipe } from './Filters/filtroSolicPadres.pipe';
import { FiltroDiamanteMttoPipe } from './Filters/filtrodiamante.pipe';
import { FiltroAgilissaPipe } from './Filters/agilissa/filtroAgilissa.pipe';
import { FiltroUsuarioPipe } from './Filters/diamante/filterUser.pipe';
import { FiltroActivooPipe } from './Filters/diamante/filtroActivo.pipe';

@NgModule({
     declarations: [
          FilterRepositoryPipe,
          UsersPipe,
          FilterUserRepositoryPipe,
          SearchVTEntrena,
          ValuesPipe,
          FiltroBuscarPipe,
          FiltroCDOPipe,
          FiltroGeneralCDOpIpe,
          FiltroPadrePipe,
          FiltroDiamanteMttoPipe,
          FiltroAgilissaPipe,
          FiltroUsuarioPipe,
          FiltroActivooPipe
     ],
     imports: [

     ],
     exports: [
          FilterRepositoryPipe,
          UsersPipe,
          FilterUserRepositoryPipe,
          SearchVTEntrena,
          ValuesPipe,
          FiltroBuscarPipe,
          FiltroCDOPipe,
          FiltroGeneralCDOpIpe,
          FiltroPadrePipe,
          FiltroDiamanteMttoPipe,
          FiltroAgilissaPipe,
          FiltroUsuarioPipe,
          FiltroActivooPipe
     ]
})

export class PipesModule {

}
