import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRepository'
})
export class FilterRepositoryPipe implements PipeTransform {

  transform(value: any[], texto: string): any[] {

    texto = texto.toLowerCase();

    const arrayReturn: any[] = value.filter((item: any) => {
      const tempName = item.infoAction.name;
      item.infoAction.name = item.infoAction.name.toLowerCase();
      if (item.infoAction.name.includes(texto)) {
        item.infoAction.name = tempName;
        return item;
      }
      
    });

    console.log(arrayReturn, 'Retorno');
    return arrayReturn;
  }

}
