import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Socket } from 'ngx-socket-io';

@Injectable({
     providedIn: 'root'
})

export class StorageWebService {

     constructor(
          private storage: Storage
     ) {

     }

     async insertUser(data: any) {
       return this.storage.get('login').then(async (dataLogin: any[]) => {
          dataLogin.push(data);
          await this.storage.set('login', dataLogin);
          console.log('Insertado!');
       }).catch((err) => {
            console.log('Error ', err);
       });
    }

    getToken() {
        return this.storage.get('login').then((dataLogin: any) => {
             return dataLogin[0].AccessToken;
        });
    }

    getComapny() {
          return this.storage.get('login').then((dataLogin: any) => {
               return dataLogin[0].CompanyID;
          });
      }
     
     async getFormActive() {
          return await this.storage.get('formActive');
     }

     async getForms() {
          return await this.storage.get('forms');
     }
     
     async getLogin() {
          return await this.storage.get('login');
     }
     async getLoginInterface() {
          return await this.storage.get('logininterface');
     }

     setLoginInterface(data) {
          this.storage.get('logininterface').then(async (dataForm: any[]) => {
               if (dataForm.length > 0) {
                    dataForm = [];
               }
               dataForm.push(data);
               await this.storage.set('logininterface', dataForm);
               console.log('Insertado!');
            }).catch((err) => {
                 console.log('Error ', err);
            });
         }
     

         async deletelogininterfaces() {
          await this.storage.set('logininterface', []);
         }
     

   
     async closeSession() {
          return await this.storage.set('login', []);
     }

    insertForms(data) {
     this.storage.get('forms').then(async (dataForm: any[]) => {
          if (dataForm.length > 0) {
               dataForm = [];
          }
          dataForm.push(data);
          await this.storage.set('forms', dataForm);
          console.log('Insertado!');
       }).catch((err) => {
            console.log('Error ', err);
       });
    }



    insertFormActive(data) {
     return this.storage.get('formActive').then(async (dataForm: any[]) => {
          if (dataForm.length > 0) {
               dataForm = [];
          }
          dataForm.push(data);
          console.log('Insertado el formulario activo!');
          return await this.storage.set('formActive', dataForm);
       }).catch((err) => {
            console.log('Error ', err);
            return err;
       });
    }

    


    insertChat(data) {
     return this.storage.get('chats').then(async (dataChat: any[]) => {
          dataChat = [];
          dataChat.push(data);
          console.log('Insertado en el chat!');
          return await this.storage.set('chats', dataChat);
       }).catch((err) => {
            console.log('Error ', err);
            return err;
       });
    }

    async getChat() {
     return await this.storage.get('chats');
    }
}

