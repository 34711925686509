import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { LoadingService } from 'src/app/Services/Utilities/Loading.service';
import { StorageWebService } from 'src/app/Services/Storage/StorageWeb.service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  titleForm = '';
  pages: any[] = [];
  page = 1;

  /*
    LocationsTypes:1
  Users:10
  AssetsType:12
  ItemsType:14
  Surveys:4
  ListsType:7
  ListsDet:8
  */
  @Input() EntityID: string;
  @Input() Entity: string;
  @Input() Value: any;

  @ViewChild('slide') slide: IonSlides;
  constructor(
    private loading: LoadingService,
    private storage: StorageWebService
  ) { }

  async ngOnInit() {

    await this.loading.createLoading('Cargando Formulario, por favor espere');

    const data = await this.storage.getForms();

    if (data) {
      console.log(data, true, this.EntityID);
      const myForm = data[0].filter((form) => {
        return form.GUID === this.EntityID;
      });

      console.log(myForm)

      this.titleForm = myForm[0].Title;
      this.pages = JSON.parse(myForm[0].JSONQuestions);
      
      console.log(this.pages)

      await this.loading.cancelLoading();
    }

    // const data = await this.storage.

    /* this.titleForm = data[0].Title;
      this.pages = data[0].JSONQuestions;*/

  }

  async changePage() {
    this.page = await this.slide.getActiveIndex() + 1;
  }

}
