import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroact'
})
export class FiltroBuscarPipe implements PipeTransform {

  transform(value: any[], txt: string) {
    console.log(value, 'valor')

    txt = txt.toLocaleLowerCase()

    if (txt == '') {
      return value;
  }
     value = value.filter((item) => {
        let fecha = item.Values.filter((act) => act.apiId == 'FECHA').length > 0 ? item.Values.filter((act) => act.apiId == 'FECHA')[0].Value.toLocaleLowerCase() : '';
        
        let obs = item.Values.filter((act) => act.apiId == 'OBSERVACIONES').length > 0 ? item.Values.filter((act) => act.apiId == 'OBSERVACIONES')[0].Value.toLocaleLowerCase() : '';

        let nov = item.Values.filter((act) => act.apiId == 'NOVEDAD').length > 0 ? item.Values.filter((act) => act.apiId == 'NOVEDAD')[0].Value.toLocaleLowerCase() : '';

        let ubi = item.Values.filter((act) => act.apiId == 'UBICACION').length > 0 ? item.Values.filter((act) => act.apiId == 'UBICACION')[0].Value.toLocaleLowerCase() : '';

        let loc = item.LocationName.toLocaleLowerCase();

        if (fecha.includes(txt) || obs.includes(txt) || nov.includes(txt) || ubi.includes(txt) || loc.includes(txt)) {
            return item;
        }
    });

    




    return value;
  }

}
