import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {

  @Input() lab;
  @Input() required;
  @Input() hel;
  @Input() pho;
  @Input() col;
  @Input() row;
  @Input() readonly;

  constructor() { }

  ngOnInit() {
    console.log(this.required, ' colores')
    if (this.pho === '') {
      this.pho = 'Escriba aqui';
    }
  }

}
