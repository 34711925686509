import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/Services/Api/Api.service';

@Component({
  selector: 'app-asignacionnettsegur',
  templateUrl: './asignacionnettsegur.component.html',
  styleUrls: ['./asignacionnettsegur.component.scss'],
})
export class AsignacionnettsegurComponent implements OnInit {

  @Input() tkn;
  @Input() data: any;

  users = [];

  selectUsersList = [];

  generados = [];

  load = true;
  fecha = '';

  constructor(
    private modalCtrl: ModalController,
    private api: ApiService
  ) { }

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss()
  }

  ionViewWillEnter() {

    this.api.getUsers(this.tkn).subscribe((data: any) => {
      console.log(data, 'usuarios')
      if (data.length > 0) {
        this.users = data.filter((item) => item.Active == 'True');
      } else {
          this.users = [];
      }

    
      
    })

  }

  cs() {
    console.log(this.fecha)
  }

  select(event) {
    const guid = event.detail.value.ID;
    if (event.detail.checked) {
      

      const validate = this.selectUsersList.filter((item) => item == guid)

      if (validate.length == 0) {
        this.selectUsersList.push(guid);
      }
      
    } else {
      this.selectUsersList = this.selectUsersList.filter((item) => item != guid)
    }

    console.log(this.selectUsersList)
  }

  createOnlyActivity(guid, data) {

    return new Promise((resolve, reject) => {
      const novedad = this.data.Values.filter((item) => item.apiId == 'OBSERVACIONES')
      const ubicacion = this.data.Values.filter((item) => item.apiId == 'UBICACION')

      this.api.aceptActivity({
        AccessToken: this.tkn,
        FormGUID: '1gp0JCZkra',
        LocationGUID: this.data.LocationGUID,
        AssetGUID: this.data.AssetGUID,
        UserGUID: guid,
        Duration: "60",
        DispachDateTime: this.fecha + ' 00:00:00',
        Values: JSON.stringify([{
          apiId: 'TRABAJOASIGNADO',
          Value: novedad.length > 0 ? novedad[0].Value : ''
        }, {
          apiId: 'UBICACIONP',
          Value: ubicacion.length > 0 ? ubicacion[0] : ''
        }]),
        ActivityGUID: '',
        ParentGUID: this.data.GUID,
        CompanyStatusGUID: 'ACA3B658-AC7E-4249-BE49-ADF2FF13979F'
      }).subscribe(async (dat: any) => {
        console.log(dat)
      
        if (dat.Status === 'OK') {

          resolve(true)

        } else {
          resolve(false)
        }
      })
    })

  }

  async createActivities() {

    this.load = false;


    for (const user of this.selectUsersList) {

      const response = await this.createOnlyActivity(user, this.data);
      const dataUser = this.users.filter((item) => item.ID == user);

      if (response) {

       

        if (dataUser.length > 0) {
          this.generados.push({
            user: dataUser[0].FirstName,
            status: true
          })
        }
       
      } else {

        if (dataUser.length > 0) {
          this.generados.push({
            user: dataUser[0].FirstName,
            status: false
          })
        }

      }
      
    }

    this.api.cambio({
      tkn: this.tkn,
      user: '6C66D879-2E6D-427B-B494-DEA52062F639',
      guid: this.data.GUID,
      status: 'ACA3B658-AC7E-4249-BE49-ADF2FF13979F'
    }).subscribe((data: any) => {

      this.load = true;

      this.modalCtrl.dismiss({data: this.data})
      
    })

   

  }

}
