import { UserRegister } from './../../Interfaces/user.interface';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Login } from '../../Interfaces/Login.interface';
import { retryWhen, delayWhen, map, timeout } from 'rxjs/operators';
import { timer, Observable } from 'rxjs';
import { environments } from 'src/environments/environment';
import * as moment from 'moment-timezone';


@Injectable({
     providedIn: 'root'
})


export class ApiService {
     constructor(
          private Http: HttpClient
     ) { }

     private HandlerError(error: Observable<any>) {
          let intentos = 0;
          return error.pipe(
               delayWhen(() => timer(5000)),
               map((errorResponse: HttpErrorResponse) => {
                              if (intentos === 3 ) {
                                   if (errorResponse instanceof ErrorEvent) {
                                        console.log('ErrorEvent');
                                        throw  new Error(' Conexion no establecida');
                                   } else if (errorResponse instanceof ProgressEvent) {
                                        console.log('ProgessEvent');
                                        throw new Error(' Conexion no establecida');
                                   } else {
                                        if (errorResponse.status === 404) {
                                             console.log('URL inv[erronea');
                                        }
                                        console.log('HttpError', errorResponse.error);
                                        throw new Error(' server ' + errorResponse.status);
                                   }
                              }

                              intentos++;
                         })
                    );
     }

     postLogin(login: Login) {
          return this.Http.get(`${environments.URL_CLUUD}Users/Authentication?login=${login.user}&password=${login.password}&lan=es`).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     
     getMyActivities(tkn) {
 
  
          return this.Http.post(`${environments.URL_API}Surveys/DispatchesByCompanyStatus`, {
               AccessToken: tkn,
               CompanyStatusName: 'CREADO',
               FormGUID: 'CE30E8A1-9D77-4CD6-97A2-8EC72826E920',
               Search: '',
               ByUser: false
          }).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     getActivitieInfo(tkn, guid) {
          return this.Http.get(`${environments.URL_API}Surveys/Activity?AccessToken=` + tkn + '&GUID=' + guid + '&ListValues=false').pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getUsers(tkn) {
          return this.Http.post(`${environments.URL_API}Users/All`, {
               AccessToken: tkn
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

    

     cambio(data) {
          return this.Http.post(`${environments.URL_API}Surveys/SetDatetime`, {
               AccessToken: data.tkn,
               UserGUID: data.guid,
               CompanyStatusGUID: data.status,
               ActivityGUID: data.guid,
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     aceptActivity(data) {
          return this.Http.post(`${environments.URL_API}Surveys/BasicDispatch`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     getAssets(data) {
          return this.Http.post(`${environments.URL_API}Assets/AssetsByTypeID`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }


     getAssetsGET(data) {
          return this.Http.get(`${environments.URL_API}Assets/AssetsByTypeID?AccessToken=${data.tkn}&AssetTypeID=${data.guid}`).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }


     updateSurvey(data) {
          return this.Http.post(`${environments.URL_API}Surveys/UpdateFields`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     getAsset(data) {
          return this.Http.post(`${environments.URL_API}Assets/AssetsByLocationID`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     deleteActivity(data) {
          return this.Http.post(`${environments.URL_API}Surveys/DeleteActivity`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }


     getLists(data) {
          return this.Http.post(`${environments.URL_API}Lists/ByTypeGUID`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     getLocs(data) {
          return this.Http.post(`${environments.URL_API}Locations/ByTypeID`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     createAsset(data) {
          return this.Http.post(`${environments.URL_API}Assets/PUT`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     createLocation(data) {
          return this.Http.post(`${environments.URL_API}Locations/PUT`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     getLocations(data) {
          return this.Http.post(`${environments.URL_API}Locations/ByTypeID`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

   



     


     childs(data) {
          return this.Http.post(`${environments.URL_API}Surveys/ActivityChilds`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     users(data) {
          return this.Http.post(`${environments.URL_API}Users/All`, data).pipe(
               timeout(30000),
               retryWhen(error => this.HandlerError(error))
          );
     }

     
     

     
     getActivities(tkn) {
          const date = moment().format("YYYY-MM-DD");
          console.log(date) 
          return this.Http.post(`${environments.URL_API}Surveys/Dispatches`, {
               AccessToken: tkn,
               DispatchDate: date,
               FormGUID: 'FCA171F7-227E-41FA-9129-CC5D3D7F20B3',
             
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getActivities2(tkn, from, to, form) {
        
          return this.Http.post(`${environments.URL_API}Surveys/Activities`, {
               AccessToken: tkn,
               From: from,
               To: to,
               FormGUID: form,
               ByUser: true
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     
     getActivities3(tkn, from, to, form) {
        
          return this.Http.post(`${environments.URL_API}Surveys/Activities`, {
               AccessToken: tkn,
               From: from,
               To: to,
               FormGUID: form,
               ByUser: true
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

   

     changeStatus(data) {
     
          return this.Http.post(`${environments.URL_API}Surveys/SetDatetime`, {
               AccessToken: data.tkn,
          
               UserGUID: data.user,
               CompanyStatusGUID: data.status,
               ActivityGUID: data.guid,
       
             
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     changeDispath(data) {
     
          return this.Http.post(`${environments.URL_API}Surveys/SetDatetime`, {
               AccessToken: data.tkn,
               DispachDateTime: data.date,
               UserGUID: data.user,
               CompanyStatusGUID: data.status,
               ActivityGUID: data.guid,
       
             
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     changeUser(data) {
     
          return this.Http.post(`${environments.URL_API}Surveys/SetDatetime`, {
               AccessToken: data.tkn,
      
               UserGUID: data.user,
               DispachDateTime: data.date,
               ActivityGUID: data.guid,
       
             
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     changeOnlyStatus(data) {
     
          return this.Http.post(`${environments.URL_API}Surveys/SetDatetime`, {
               AccessToken: data.tkn,
               DispachDateTime: data.date,
               ActivityGUID: data.guid,
       
             
          }).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     



     postLoginExte(login: Login) {
          return this.Http.post(`${environments.URL_NODE}/customers/login`, login).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getForm(userVT: UserRegister, params: any) {
          console.log(userVT, 'servicio')
          // tslint:disable-next-line:max-line-length
          return this.Http.get(`${environments.URL_CLUUD}Mobile/GetModuleInfo?tkn=${userVT.AccessToken}&key=FORMS&id=-1&f1=&f2=&f3=&lan=es`).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getHelp(userVT: any) {
          return this.Http.get(`${environments.URL_API}Help?tkn=${userVT.AccessToken}`).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     getUsersCompany(userVT: any) {
          return this.Http.get(`${environments.URL_CLUUD}Users/GetModuleInfo?tkn=${userVT.AccessToken}&key=USERSCUSTOMS`).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     createdCategory(dataCategory: any) {
          return this.Http.post(`${environments.URL_NODE}/categories`, dataCategory).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     alterCategory(dataCategory: any) {
          return this.Http.put(`${environments.URL_NODE}/categories/one/`, dataCategory).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getCategory(company: any, userID) {
          const options = {
               headers: new HttpHeaders({
                    'x-company': company,
                    'x-userID': userID
               })
          };
          return this.Http.get(`${environments.URL_NODE}/categories`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     deletedCategory(id: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-id': id
               })
          };
          return this.Http.delete(`${environments.URL_NODE}/categories/one`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     createdFolder(dataCategory: any) {
          return this.Http.post(`${environments.URL_NODE}/folders/folder`, dataCategory).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getFolders(parentId: any, type: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-parentId': parentId,
                    'x-type': parentId
               })
          };
          return this.Http.get(`${environments.URL_NODE}/folders`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getPathFolder(parentId: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-parentID': parentId
               })
          };
          return this.Http.get(`${environments.URL_NODE}/folders/path/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     download(path: any) {
          
          return this.Http.post(`${environments.URL_NODE}/download`, path).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     deletedFolder(id: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-id': id
               })
          };
          return this.Http.delete(`${environments.URL_NODE}/folders/one`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     recoverFolder(id: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-id': id
               })
          };
          return this.Http.put(`${environments.URL_NODE}/folders/recover`, {}, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     // CONNECTIONS LOGIN

     getConnections(company) {

          const options = {
               headers: new HttpHeaders({
                    'x-company': company
               })
          };
          return this.Http.get(`${environments.URL_NODE}/connections`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );

     }


     getChats(idUser) {

          const options = {
               headers: new HttpHeaders({
                    'x-ID': idUser
               })
          };
          return this.Http.get(`${environments.URL_NODE}/chat/mychats`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );

     }

     getOneChat(id) {

          const options = {
               headers: new HttpHeaders({
                    'x-ID': id
               })
          };
          return this.Http.get(`${environments.URL_NODE}/chat/mychats/onechat`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );

     }

     setChat(data) {

          return this.Http.post(`${environments.URL_NODE}/chat/mychats/onechat`, data).pipe(
               retryWhen(error => this.HandlerError(error))
          );

     }

     // CUSTOMERS 

     createdCustomer(data) {
          return this.Http.post(`${environments.URL_NODE}/customers/`, data).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     getCustomersCompany(comapny) {

          const options = {
               headers: new HttpHeaders({
                    'x-companyID': comapny
               })
          };
          return this.Http.get(`${environments.URL_NODE}/customers/company`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );

     }


     alterPermiss(data) {
          return this.Http.put(`${environments.URL_NODE}/categories/one/permiss/`, data).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

}
