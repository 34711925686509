import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroUsuario'
})
export class FiltroUsuarioPipe implements PipeTransform {

  transform(value: any[], text: string) {
   
    text = text.toLowerCase()

    return text == '' ? value : value.filter((item) => {
        let name = item.FirstName.toLowerCase();
        let last = item.LastName.toLowerCase();

   
        return name.includes(text) || last.includes(text);
    });
  }

}
