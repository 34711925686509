import { environments } from './../../../environments/environment.prod';
import { LoginEntrena } from './../../Interfaces/loginEntrena.interface';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delayWhen, retryWhen } from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
     providedIn: 'root'
})

export class ApiEntrenaService {
     
     constructor(
          private http: HttpClient
     ) { }

     private HandlerError(error: Observable<any>) {
          let intentos = 0;
          return error.pipe(
               delayWhen(() => timer(5000)),
               map((errorResponse: HttpErrorResponse) => {
                              if (intentos === 3 ) {
                                   if (errorResponse instanceof ErrorEvent) {
                                        console.log('ErrorEvent');
                                        throw  new Error(' Not Connection');
                                   } else if (errorResponse instanceof ProgressEvent) {
                                        console.log('ProgessEvent');
                                        throw new Error(' Not Connection');
                                   } else {
                                        if (errorResponse.status === 404) {
                                             console.log('URL Invalid');
                                        }
                                        console.log('HttpError', errorResponse.error);
                                        throw new Error(' Internal Error ' + errorResponse.status);
                                   }
                              }

                              intentos++;
                         })
                    );
     }


     public loginUser(data: LoginEntrena, IP, navigator) {
          return this.http.post(`${environments.URL_NODE}/vtentrena/user/login/`, {data, IP, navigator}).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public existUser(data: any) {
          return this.http.post(`${environments.URL_NODE}/vtentrena/user/exist/`, data).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public createdUserBasic(data: any, token: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.post(`${environments.URL_NODE}/vtentrena/user/basic/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public getUsersBasic(token: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/users/basic/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public modifyUserBasic(data: any, token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.put(`${environments.URL_NODE}/vtentrena/user/basic/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public deleteUserBasic(token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.delete(`${environments.URL_NODE}/vtentrena/user/basic/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     // DESIGNER SERVICES HTTP

     public createDesigner(token: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.post(`${environments.URL_NODE}/vtentrena/designer/new/`, {}, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public updateDesigner(token: string, data: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.put(`${environments.URL_NODE}/vtentrena/designer/update/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public deleteDesigner(token: string, id: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.put(`${environments.URL_NODE}/vtentrena/designer/delete`, {_id: id}, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public addUsersDesing(token: string, data) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.put(`${environments.URL_NODE}/vtentrena/designer/add`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public readDesigner(token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/designer/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public readDesignersAll(token: string, id) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-parentAdmin': id
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/designer/all`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public upload(formData, token) {
          const options: any = {
               headers: new HttpHeaders({
                    'x-token': token,
               }),
               reportProgress: true,
               observe: 'events'
          };

          return this.http.post(`${environments.URL_NODE}/vtentrena/designer/upload/`, formData, options);
     }


     public createdAnswer(token: string, data) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.post(`${environments.URL_NODE}/vtentrena/designer/answer/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public createdAnswerOther(token: string, data) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.post(`${environments.URL_NODE}/vtentrena/designer/answer/other`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public updateAnswer(token: string, data: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.put(`${environments.URL_NODE}/vtentrena/designer/answer/update/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public readAnswerJson(token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/designer/answer/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     } 


     public readAnswerJsonAll(token: string, id: string, iduser: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id,
                    'x-iduser': iduser
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/designer/answer/all`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     } 

     public readIP() {
          return this.http.get(`http://api.ipify.org/?format=json`).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public updateAnswerSave(token: string, data: any) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token
               })
          };
          return this.http.put(`${environments.URL_NODE}/vtentrena/designer/answer/save/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


      public LogoutUser(data: LoginEntrena, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.post(`${environments.URL_NODE}/vtentrena/user/logout/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public statusUser(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.put(`${environments.URL_NODE}/vtentrena/user/status/`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public readLogs(token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/user/logs/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     } 

     

     public exportLogs(token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/user/logs/export/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public exportPDF(token: string, id: string) {
          const options = {
               headers: new HttpHeaders({
                    'x-token': token,
                    'x-id': id
               })
          };
          return this.http.get(`${environments.URL_NODE}/vtentrena/designer/answer/pdf/`, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public uploadPreference(formData, token) {
          const options: any = {
               headers: new HttpHeaders({
                    'x-token': token,
               }),
               reportProgress: true,
               observe: 'events'
          };

          return this.http.post(`${environments.URL_NODE}/vtentrena/preferences/upload`, formData, options);
     }


     public preferences(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.put(`${environments.URL_NODE}/vtentrena/preferences/theme`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public preferencesLoad(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.put(`${environments.URL_NODE}/vtentrena/preferences`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public comments(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.post(`${environments.URL_NODE}/vtentrena/comments`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     public getComments(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.put(`${environments.URL_NODE}/vtentrena/comments`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

     

     public getCommentsAdmin(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.put(`${environments.URL_NODE}/vtentrena/comments/admin`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }


     public createCommentChildrens(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.post(`${environments.URL_NODE}/vtentrena/comments/childrens`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }



     public getCommentChildrens(data, token) {
          const options = {
                headers: new HttpHeaders({
                     'x-token': token
                })
           };
          return this.http.put(`${environments.URL_NODE}/vtentrena/comments/childrens`, data, options).pipe(
               retryWhen(error => this.HandlerError(error))
          );
     }

}
