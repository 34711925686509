import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageWebService } from '../Services/Storage/StorageWeb.service';
import { MenuController } from '@ionic/angular';
import { DataComparnyService } from '../Services/dataCompany.service';

@Injectable({
  providedIn: 'root'
})
export class EntrenaGuard implements CanActivate {

  constructor(
    private storage: StorageWebService,
    private router: Router,
    private menuCtrl: MenuController,
    private dataCompany: DataComparnyService
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    return this.storage.getLogin().then((data) => {
      if (data.length > 0) {
           this.menuCtrl.enable(true, 'menu');
           if (data[0].typeApp === '2') {
            this.dataCompany.getVista('3');
            return true;
           } else if (data[0].typeApp === '1') {
            this.router.navigate(['/forms']);
            return false;
           }
           
      } else {
           console.log('Entramos al login')
           this.router.navigate(['/login']);
           return false;
      }
   }).catch(() => {
        console.log('Error');
        this.router.navigate(['/login']);
        return false;
   });
  }
  
}
