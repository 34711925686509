import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrosolicpadres'
})
export class FiltroPadrePipe implements PipeTransform {

  transform(value: any[], filtroby: string) {
   
    filtroby = filtroby.toLowerCase()

    

    return filtroby == '' ? value : value.filter((item) => {
        let loc = item.hab.toLowerCase();
        let asset = item.cam.toLowerCase();

   
        return loc.includes(filtroby) || asset.includes(filtroby);
    } );
  }

}
