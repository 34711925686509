import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchVTEntrena implements PipeTransform {

     transform(values: any[], param: string, property: string) {
          param = param.toLowerCase();
          if (param === '') {
               return values;
          } else {
               return values.filter((item) => {
                    const val = item[property].toLowerCase();
                    if (val.includes(param)) {
                         return item;
                    }
               })
          }
     }

}